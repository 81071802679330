import React from 'react'
import { ToolsData } from '../../data/toolsData'
import ToolsCard from './ToolsCard/toolsCard'
import {ToolsContainerStyle} from './toolsContainer.module.css'


const ToolsContainer = () => {
    return (
        <div className={ToolsContainerStyle}>
            {ToolsData.map(tool => <ToolsCard href={tool.toolURL} name={tool.name} para={tool.description} imageURL={tool.photoURL}/>)}
        </div>
    )
}

export default ToolsContainer
