import React, { CSSProperties } from 'react';

import './paragraph.css';

interface ParagraphProps {
  children: string;
  className?: string;
  style?: CSSProperties;
}

const Paragraph = ({ children, className, style }: ParagraphProps) => {
  return (
        <p style={style} className={className ? `Paragraph ${className}` : 'Paragraph'}>
            {children}
        </p>
  );
};

export default Paragraph;
