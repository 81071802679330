import React from 'react'
import {ToolsCardStyle, ToolsCardH3, ToolsCardContainer, ToolsCardImageContainer, ToolsCardImage } from './toolsCard.module.css'
import Tilt from 'react-parallax-tilt';
import PlaceImage from '../../Blocks/PlaceImage/placeImage';
import Paragraph from '../../Blocks/Paragraph/paragraph';
import H3 from '../../Blocks/H3/h3';
import { Link } from 'gatsby';


const ToolsCard = ({imageURL, name, para, href}) => {
    return (
        <Tilt gyroscope={true}>
            <Link to={href}>
                <div className={ToolsCardStyle}>
                    <div className={ToolsCardContainer}>
                        <div className={ToolsCardImageContainer}>
                        <PlaceImage nozoom imageClass={ToolsCardImage} filename={imageURL} alt={name}/>
                    </div>
                    <H3 className={ToolsCardH3}>{name}</H3>
                    <Paragraph>{para}</Paragraph>
                    </div>
                </div>
            </Link>
        </Tilt>

    )
}

export default ToolsCard
