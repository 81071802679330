import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import Hero from '../components/Hero/hero';
import Layout from '../components/layout';
import SEO from '../components/SEO/seo';
import ToolsContainer from '../components/ToolsContainer/toolsContainer';

const ToolsPage = () => {
  const { site } = useStaticQuery(
            graphql`
              query {
                site {
                  siteMetadata {
                    siteUrl
                  }
                }
              }
            `,
      );

  return (
      <Layout>
            <SEO lang="ru" title='Tools' description="Artyom Alekseev — Product Designer, frontend develop and person who love to do what he love." keywords={['designer', 'artyom', 'alekseev', 'Artyom Alekseev', 'ux', 'ui', 'дизайнер', 'фронтенд разработчик', 'arduino', 'smarthome', 'подкаст', 'подкастер']}>
            </SEO>
            <Hero heading="Tools" description="The tools designed to improve my productivity."/>
            <ToolsContainer />
      </Layout>
      ); };

export default ToolsPage;
