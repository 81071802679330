export const ToolsData = [
    // {
    //     name: "Blur Text Extension",
    //     description: "Chrome Extension for UI Designers",
    //     photoURL: "socialCover.png",
    //     toolURL: "/tools/blurText",
    // },
    {
        name: "Notion Dashboard",
        description: "Improve your workflow with Flow concept and Notion",
        photoURL: "dashboard.png",
        toolURL: "/tools/dashboard",
    },
    {
        name: "Notion Task Helper",
        description: "Never forget new ideas and tasks",
        photoURL: "telegramTasks.png",
        toolURL: "/tools/fedro",
    },
    {
        name: "File Cover for Figma",
        description: "Find your designs easier",
        photoURL: "covers.png",
        toolURL: "/tools/figma-covers",
    },
    {
        name: "Font Checker for Figma",
        description: "Compare fonts side by side.",
        photoURL: "fontTester.png",
        toolURL: "/tools/figma-font-checker",
    },
]